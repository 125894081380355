import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import DesignerCard from '../components/DesignerCard';
import DressCounter from '../components/DressCounter';
import Data from '../assets/data/designer-data.json';
import ReactGA from 'react-ga'
// import Toast from './Toast';

export const initGA = () => {
  ReactGA.initialize('UA-128840410-1')
}

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname + window.location.search})
  ReactGA.pageview(window.location.pathname + window.location.search)
}

const DesignerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  & li {
    display: inline-block;
    margin: 32px;
    width: calc(95% - 32px);

    @media (min-width: 960px) {
      width: calc(46% - 32px);
    }

    @media (min-width: 1620px) {
      width: calc(30% - 32px);
    }
  }
`;


class OurDesigners extends React.Component {

  componentDidMount () {
    initGA()
    logPageView()
  }

    handleDesignerClick(d) {
        this.props.history.push({
            pathname: `/designer/${d.title}`,
            search: ``,
            params: {
                designer: d.title
            }
        });
        window.scrollTo(0,0);
    }

  render() {
    return (
      <Layout theme="dark" stickyNav="sticky-nav" sticky="true">
        {/* <Toast></Toast> */}
        <div className="main-body page-vs-nav">
          <h1 className="script">Our Designers</h1>
          <p>We have carefully selected our designers for their exclusivity to us and so we have a great range of styles for our brides to choose from. View our designers below;</p>
          
          <DesignerContainer>
              {
              Data.filter(designer => designer.showOnDesignerPage === true).map((d, id) => <DesignerCard
                key={id}
                dresses={<DressCounter id={id}/>}
                title={d.title}
                onClick={() => this.handleDesignerClick(d)}
                bg={d.bg}
              />
              )}
          </DesignerContainer>
        </div>
      </Layout>
    );
  }
}

export default OurDesigners;
