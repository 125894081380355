import React from 'react';
import styled from 'styled-components';
// import SaleBadge from './SaleBadge';

const BannerEl = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;    
    background: #ed3d3d;
    margin: 0;
    // padding: 1rem 0;

    @media screen and (max-width: 760px) {
        flex-direction: column;
    }

    p {
        font-weight: bold;
        color: white;
        // margin: 0;
        // padding-top: .5rem;
        // padding-right: 32px;
        font-size: 1.3rem;
        // text-transform: uppercase;

        &.center {
            text-align: center;
        }

        @media screen and (max-width: 760px) {
            padding-right: 0;
            margin-bottom: 8px;
        }
    }

    a {
        color: #906A60;
        border: none;
        border-radius: 4px;
        padding: 8px 12px;
        background: #B2938B;
        &:hover {
            color: #B2938B;
            background: white;
            }
        @media screen and (max-width: 760px) {
            color: #B2938B;
            background: white;
            }
    }

    @media screen and (max-width: 760px) {
        padding: 16px 5%; 
      }
`;

const Banner = () => (
    <BannerEl>
        {/* <SaleBadge class="banner" text="Huge Clearance Sale!" /> */}
        <p className='center'>
        Closing down sale - all dresses are now half price or less</p>
        {/* <a href="">See our Prom Collection</a> */}
    </BannerEl>
)




export default Banner;