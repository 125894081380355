import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Layout from '../components/Layout';
import Overlay from '../components/Overlay';
import contact from '../assets/images/contact.jpg';
import openingHoursIcon from '../assets/images/opening-hours-icon.svg';
import addressIcon from '../assets/images/address-icon.svg';
import phoneIcon from '../assets/images/phone-icon.svg';
// import Toast from '../components/Toast'

import ReactGA from 'react-ga'

export const initGA = () => {
  
  ReactGA.initialize('UA-128840410-1')
}
export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname + window.location.search})
  ReactGA.pageview(window.location.pathname + window.location.search)
}

const MapConatiner = styled.div`
    position: absolute;
    top: 30px;
    right: -24px;
    width: 50%;
    height: 400px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    border-radius: 4px;

    & .map-style {
        display: block;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        border: 0;
    }

    @media (max-width: 800px) {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        right: auto;
    }
`;

const ParkingText = styled.div`
    color: #666;
    padding: 0 8px 4px 8px;
`;

const DetailsContainer = styled.div`
    padding-left: 48px;
    width: 50%;

    @media (max-width: 800px) {
        padding-left: 0px;
        width: 100%;
    }
`;

const ShopImageContact = styled.div`
    width: 100%;
    height: 300px;
    background-image: url(${contact});
    background-size: cover;
    background-position: center center;
    display: block;
    position: relative;
    margin-top: 56px;
`;

const ContactDetailsCard = styled.div`
    width: 90%;
    height: auto;
    margin-left: 5%;
    margin-top: 40px;
    background: white;
    position: relative;
    border-radius: 3px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    top: -64px;
    padding: 40px 24px;
    display: block;
    flex-direction: column;

    & button {
        display: block;
        margin: auto;

        @media (max-width: 800px) {
            margin-top: 60px;
        }

    }

    & ul {
        list-style: none;
        display: block;
        padding-top: 0;
        margin: 0 0 48px 16px;
        line-height: 26px;
        font-size: 16px;
        color: #6C6C6C;
    }

    & .title {
        display: flex;
        align-items: center;
    }

    & h3 {
        display: inline-block;
        margin: 0 0 0 16px;
        padding: 0;
    }

    & span {
        & img {
            &.opening-icon {
                width: 32px;
            }
            &.address-icon {
                width: 24px;
            }
        }
    }
`;


class Homepage extends React.Component {
    constructor(){
        super();
        this.state = {
          isActive: false
        }
    }
    
    handleClick() {
        this.setState({isActive: !this.state.isActive})
        window.scrollTo(0,0);
    }
    componentDidMount () {
        initGA()
        logPageView()
      }

  render() {
    return (
        <Layout theme="dark" stickyNav="sticky-nav" sticky="true">
            {/* <Toast /> */}
            <Overlay isActive={this.state.isActive} onClick={() => this.handleClick()}/>

            <div className="main-body page-vs-nav">
                <h2 className="script">Contact us</h2>
                <p>Please feel free to <NavLink className="a" to="/appointments">request an appointment</NavLink> or <a href="mailto:enquiries@amoraebridal.co.uk">e-mail us directly</a>, we look forward to hearing from you.</p>
            </div>
            <ShopImageContact></ShopImageContact>
                <ContactDetailsCard>
                    <DetailsContainer>
                        <div className="title">
                            <span><img className="opening-icon" src={openingHoursIcon} alt="" /></span>
                            <h3>Opening Hours</h3>
                        </div>
                        <ul> 
                            <li>Monday: Closed</li>
                            <li>Tuesday - Friday: 10am - 4:00pm</li>
                            <li>Saturday: 9:30am - 5:00pm</li>
                            <li>Sunday: Closed</li>
                            {/* <li><small>We're currently closed until January 14th</small></li> */}
                        </ul>

                        <div className="title">
                            <span><img className="address-icon" src={addressIcon} alt="" /></span>
                            <h3>Our Address</h3>
                        </div>
                        <ul> 
                            <li>123 High street,<br />Chipping Ongar,<br />Essex,<br />CM5 9JA<br /></li>
                        </ul>

                        <div className="title">
                            <span><img className="opening-icon" src={phoneIcon} alt="" /></span>
                            <h3>Contact Info</h3>
                        </div>
                        <ul> 
                            <li>Phone: 012 7736 6436</li>
                            <li>Email: enquiries@amoraebridal.co.uk</li>
                        </ul>

                    </DetailsContainer>

                    <MapConatiner>
                    <iframe
                        title="GoogleMaps"
                        className="map-style"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2472.4538398055633!2d0.244987!3d51.706438999999996!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d89649a2316975%3A0x745e1131df64f586!2sAmorae!5e0!3m2!1sen!2sie!4v1415369319503">
                    </iframe>
                 
                    <ParkingText>
                        <p>Parking: If you are coming for an appointment then the best place to park is at <a href="https://goo.gl/maps/iwZ237HYdAk9GH3h9">Sainsbury's</a>(CM5 9AR) or the <a href="https://goo.gl/maps/bDT4gTCMif3cLWut5">Ongar library</a>(CM5 9AB).</p>
                    </ParkingText>
                 </MapConatiner>
                </ContactDetailsCard>
        </Layout>
    );
  }
}

export default Homepage;