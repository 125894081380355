import React from 'react';
import styled from 'styled-components';
import Layout from './Layout';
import Overlay from './Overlay';
import Checkbox from './Checkbox';
import AppointmentsHero from '../assets/images/booking.jpg';
// import Toast from './Toast';

import Button from './Button';
import ReactGA from 'react-ga'

export const initGA = () => {
  
  ReactGA.initialize('UA-128840410-1')
}
export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname + window.location.search})
  ReactGA.pageview(window.location.pathname + window.location.search)
}
const AppointmentContainer = styled.div`
    width: 80%;
    height: auto;
    margin-left: 10%;
    margin-top: 40px;
    background: white;
    position: relative;
    border-radius: 3px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    top: -64px;
    padding: 40px 24px;
    display: block;

    @media (min-width: 1200px) {
        width: 1000px;
        margin: auto;
    }

    & label {
        font-size: 0.9rem;
        color: #4c4c4c;
    }

    & h3 {
        margin-top: 4%;
        font-family: Niconne;
        font-size: 2rem;
        color: #B2938B;
    }
}
`;

const YourDetailsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const InputBlock = styled.div`
    display: flex;
    margin: 16px 0 0 0;
    flex-direction: column;
    width: 30%;

    @media (max-width: 836px) {
        width: 100%;
    }

    &.display-as-row {
        width: 100%
        flex-direction: row;
        justify-content: flex-grow;
        flex-wrap: wrap;
        position: relative;
        margin: 0;

        @media (max-width: 836px) {
            width: 100%;
        }
    }

    &.large-input {
        width: 60%;
    
        @media (max-width: 836px) {
            width: 100%;
        }
    
    }
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
`;

const FormControls = styled.div`
    margin: 24px 0 0 0;
    padding-bottom: 64px;
    text-align: center;
`;

const HeroImage = styled.div`
    width: 100%;
    height: 300px;
    background-image: url(${AppointmentsHero});
    background-size: cover;
    background-position: center center
    display: block;
    position: relative;
    margin-top: 56px;
`;


class Appointments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            name: "",
            address: "",
            number: "",
            email: "",
            weddingDate: "",
            budget: "",
            preferredDay: "",
            timeAvailablity: [
                {id: 0, key: ".1", displayValue: "Morning", available: false},
                {id: 1, key: ".2", displayValue: "Afternoon", available: false}
            ],
            yourSize: "",
            message: ""
        }
    }

    handleRadioClick(item) {
        let timeAvailability = [...this.state.timeAvailablity]
        
        timeAvailability.forEach(timeAvailable => {
            timeAvailable.available = timeAvailable.id === item.id
        });

        this.setState({timeAvailability})
    }

  render() {

    const honeyPot = {
        display: 'none'
      };

    return (
        <Layout theme="dark" stickyNav="sticky-nav" sticky="true">

            {/* <Toast /> */}
            <Overlay isActive={this.state.isActive} onClick={() => this.handleClick()}/>

            <div className="main-body page-vs-nav">
                <h2 className="script">Request an appointment</h2>
                <p>Request an appointment below and we'll be in-touch shortly. You can also <a href="mailto:enquiries@amoraebridal.co.uk">email</a> or call us on <a href="tel: +441277366436">+44 1277 366 436</a>.</p>
            </div>
            <HeroImage></HeroImage>
                
            <React.Fragment>
                <AppointmentContainer>
                    <form id="ContactForm" action="https://submit-form.com/2a52f4c1-dc62-47e1-ba9c-a2d08dfda7d1">
                    
                        <input type="checkbox" style={honeyPot} name="_honeypot" tabIndex="-1" autoComplete="off" />
                        <h3>Your details</h3>
                        <YourDetailsWrapper>
                            <InputBlock>
                                <label htmlFor="name">Full Name</label>
                                <input
                                placeholder="e.g. Jane Doe"
                                name="1-Name"
                                id="name"
                                value={this.state.name}
                                onChange={e => this.setState({name: e.target.value})}
                                required />
                            </InputBlock>

                            <InputBlock>
                                <label htmlFor="number">Contact Number</label>
                                <input
                                id="number"
                                placeholder="e.g. 07911123456"
                                name="3-Phone Number"
                                value={this.state.number}
                                onChange={e => this.setState({number: e.target.value})} />
                            </InputBlock>

                            <InputBlock>
                                <label htmlFor="email">Email Address</label>
                                <input
                                placeholder="Your Email"
                                id="email"
                                name="_replyto"
                                value={this.state.email}
                                type="email"
                                onChange={e => this.setState({email: e.target.value})}
                                required /> 
                            </InputBlock>

                            <InputBlock className="large-input">
                                <label htmlFor="address">Address</label>
                                <input
                                placeholder="e.g. 123 High street, Chipping Ongar, Essex, CM5 9JA"
                                name="2-Address"
                                value={this.state.address}
                                onChange={e => this.setState({address: e.target.value})}
                                required />
                            </InputBlock>
                        </YourDetailsWrapper>

                        <h3>Appointment details</h3>
                        <YourDetailsWrapper>
                            <InputBlock>
                                <label htmlFor="weddingDate">Wedding Date</label>
                                <input
                                id="weddingDate"
                                placeholder="Your Wedding Date"
                                name="4-Wedding Date"
                                type="text"
                                required />
                            </InputBlock>

                            <InputBlock>
                            <label htmlFor="budget">Budget</label>
                            <select
                                name="5-Budget"
                                id="budget"
                                value={this.state.budget}
                                placeholder="Your budget"
                                onChange={e => this.setState({budget: e.target.value})}>
                                    <option value="" disabled selected>Please select...</option>
                                    <option value="Under £1000">Under £1000</option>
                                    <option value="Over 1000">Over 1000</option>
                            </select>
                            </InputBlock>

                            <InputBlock>
                            <label htmlFor="size">Your Size</label>
                            <select
                                name="6-Size"
                                id="size"
                                value={this.state.yourSize}
                                placeholder="Please"
                                onChange={e => this.setState({yourSize: e.target.value})}>
                                    <option value="" disabled selected>Your size</option>
                                    <option value="UK 6">UK 6</option>
                                    <option value="UK 8">UK 8</option>
                                    <option value="UK 10">UK 10</option>
                                    <option value="UK 12">UK 12</option>
                                    <option value="UK 14">UK 14</option>
                                    <option value="UK 16">UK 16</option>
                                    <option value="UK 18">UK 18</option>
                                    <option value="UK 20">UK 20</option>
                                    <option value="Plus">Plus</option>
                            </select>
                            </InputBlock>
                            </YourDetailsWrapper>

                            <YourDetailsWrapper>
                                <InputBlock>
                                    <label htmlFor="preferredDay">Preferred Appointment Day</label>
                                    <input
                                    id="preferredDay"
                                    placeholder="preferred appointment day"
                                    name="7-Preferred Day"
                                    // value={this.state.preferredDay}
                                    type="date"
                                    required />
                                </InputBlock>

                                <RowContainer>
                                <label htmlFor="preferredTime">Your Preferred Time</label>
                                <InputBlock className="display-as-row">
                                {this.state.timeAvailablity.map((item, index) => (
                                    <Checkbox
                                        name={"8-Preferred Time"}
                                        displayValue={item.displayValue}
                                        id={index}
                                        isChecked={this.state.timeAvailablity[index].available}
                                        onChange={() => this.handleRadioClick(item)}
                                        value={item.displayValue}
                                            />
                                        ))}  
                                </InputBlock>
                                </RowContainer>
                                </YourDetailsWrapper>

                                <YourDetailsWrapper>
                                <InputBlock className="large-input">
                                    <label htmlFor="message">Please add any message or comments</label>
                                    <textarea 
                                        name="9-Message"
                                        id="message"
                                        value={this.state.message}
                                        placeholder="e.g. I would like to book an appointment for next week before 13:00, please..."
                                        onChange={e => this.setState({message: e.target.value})}>
                                    </textarea>
                                </InputBlock>
                            </YourDetailsWrapper>
                        
                    <FormControls>
                        <Button btnClass="primary no-icon" btnText="Send your appointment request" type="submit" />
                    </FormControls>
                    </form>
                </AppointmentContainer>
            </ React.Fragment>


        </Layout>
    );
  }
}

export default Appointments;