import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Overlay from '../components/Overlay';
import SocialMedia from '../components/SocialMedia';
import '../assets/css/navigation.css';

const Hamburger = styled.div`
    display: block;
    width: 32px;
    height: 25px;
    position: absolute;
    right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 86;
    transition: all .25s ease-in-out;

    @media screen and (max-width: 640px) {
        bottom: 22px;

    }

    span.dark {
        background-color: #4a4a4a;
    }

    &.is-open {
        position: fixed;
    }

`;

const NavPanel = styled.div`
    background: white;
    height: 100vh;
    overflow-y: scroll;
    display: block;
    position: fixed;
    padding: 0 40px;
    width: 100vw;
    top: 0;
    right: -100%;
    transition: all .15s ease-in-out;
    z-index: 85;

    &.is-open {
        right: 0;
    }

    @media screen and (min-width: 550px) {
        width: 80%;
      }

    @media screen and (min-width: 640px) {
        width: 65%;
      }

      @media screen and (min-width: 960px) {
        width: 40%;
      }
`;

const Nav = styled.nav`
    margin-top: 72px;
    border-bottom: 1px solid #666;
    
    & ul {
        list-style: none;
        padding-left: 0;

        & li {
            position: relative;
            margin: 32px 0;
            
            opacity: 0.8;
            cursor: pointer;
        }

        & ul {
            display: none;
            transition: all .25s;
            padding 0 0 0 8px;
            &:focus {outline: none;}
            &.is-open {
                display: block;
            }

            & li {
                margin: 8px 0;
                padding: 8px 16px;
                border-radius: 4px;

                &:hover {
                    opacity: 1;
                    color: #4a4a4a;
                    background: #eee;
                }

                & h2 {
                    font-size: 20px;
                }
            }
        }
    }
`;

class Navigation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            active: false,
            overlay: false,
            navDropdown: false,
            navPanel: false,
            appointment: false
        }
    }

    goToTop() {
        window.scrollTo(0,0);
    }

    handleNavItemClick() {
        this.handleNav();
        this.goToTop();
    }

    handleNav() {
        this.setState({ active: !this.state.active, navPanel: !this.state.navPanel, overlay: !this.state.overlay });
    }

    closeOverlay() {
        this.setState({ overlay: false, active: false, appointment: false, navPanel: false }); 
        const stickyNav = document.getElementsByClassName('sticky-nav');
        if(stickyNav.length <=0) {
            return false
        } else {
            stickyNav[0].classList.add("sticky")
        }
    }

    handleDesignerClick(d) {
        this.goToTop();
        const title = d.title.toLowerCase().split(' ').join('');
        this.props.history.push({
            pathname: '/designer',
            search: title
    });

        this.setState({ overlay: false, active: false, appointment: false, navPanel: false });
    }

    handleDropdown() {
        this.setState({ navDropdown: !this.state.navDropdown});
    }

    handleAppointment() {
        this.setState({
            active: !this.state.overlay,
            navPanel: !this.state.navPanel,
            appointment: !this.state.appointment
        });
        this.goToTop();
        const stickyNav = document.getElementsByClassName('sticky-nav');
        if(stickyNav.length <=0) {
            return false
        } else {
            stickyNav[0].classList.remove("sticky")
        }
    }



    render(){
        
        if (typeof window !== 'undefined') {
            this.state.active ? document.body.style.overflowY = "hidden" : document.body.style.overflowY= "scroll";
            // this.state.active ? document.body.style.height = "100vh" : document.body.style.height= "auto";
        }

        return(
            <React.Fragment>
            <Overlay coverNav={this.state.appointment} onClick={() => this.closeOverlay()} isActive={this.state.overlay} />
            <Hamburger
                onClick={() => this.handleNav()}
                className={this.state.active ? 'is-open' : ''}>
                <span className={(`${this.props.theme} ${this.state.active ? 'is-open' : ''} top`)}></span>
                <span className={(`${this.props.theme} ${this.state.active ? 'is-open' : ''} middle`)}></span>
                <span className={(`${this.props.theme} ${this.state.active ? 'is-open' : ''} bottom`)}></span>
            </Hamburger>
            <NavPanel className={(`${this.state.navPanel ? 'is-open' : 'null'}`)}>
                <Nav>
                    <ul>
                    <NavLink
                        className="navEl"
                        to="/"
                        onClick={() => this.handleNav()}>
                        <li><h2>Home</h2></li>
                    </NavLink>
                    <NavLink
                        className="navEl"
                        to="/ourdesigners"
                        onClick={() => this.handleNav()}>
                        <li><h2>Our Designers</h2></li>
                    </NavLink>
        
                        {/* <NavLink to="/samplesale" onClick={() => this.handleNavItemClick()}><li><h2>Sample Sale</h2></li></NavLink> */}
                        <NavLink
                            className="navEl"
                            to="/appointments"
                            onClick={() => this.handleNav()}>
                            <li><h2>Request an Appointment</h2></li>
                        </NavLink>
                        <NavLink to="/contact" onClick={() => this.handleNav()}><li><h2>Contact us</h2></li></NavLink>
                    </ul>
                </Nav>
                <SocialMedia />
            </NavPanel>
        </React.Fragment>


        )
    }
}



export default withRouter(Navigation)
