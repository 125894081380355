import React from 'react';
import styled from 'styled-components';
import SaleBadge from '../components/SaleBadge';

const DressContainer = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: row;
    align-content: center;
    margin: 24px 16px;

    @media screen and (min-width: 1185px) {
        width: 30%;
      }

`;

const DressCards = styled.div`
    border-radius: 4px;
    position: relative;

    & img {
        display: flex;
        width: 100%;
        height: auto;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        border-radius: 4px;
        z-index: 5;
        position: relative;
        transition: all 5s ease-in-out;
        cursor: pointer;
    }
`;

const DressInfo = styled.div`
    position: absolute;
    bottom: 24px;
    left: 16px;
    z-index: 100;
    text-align: left;

    & h2 {
        color: white!important;
        font-weight: lighter!important;
        font-size: 2.5em!important;
        margin-bottom: 0;
        cursor: pointer;
    }
`;

const SaleInfo = styled.span`
    color: white!important;
    font-size: 14px;
    font-style: italic;
    
    & span {
        display: none;
        padding-right: 10px;

        &.is-showing {
            display: inline-block;
        }
    }
`;

const BudgetInfo = styled.span`
    display: none;
    color: white!important;
    &.is-showing {
        display: block;
    }
    & span {
        display: block;
        padding-right: 10px;
    }
`;

const DressOverlay = styled.span`
    pointer-events: none;
    &:after {
        content: '';
        position: absolute !important;
        border-radius: 4px;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 8;
        background: -moz-linear-gradient(bottom, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to top, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
    }
`;

class DressCard extends React.Component {

  render() {
    
    return (
        <DressContainer>
            <DressCards onClick={this.props.onClick}>
            <SaleBadge onSale={this.props.onSale} text="Sale" />
                <img src={this.props.bg} alt={`Wedding dress named ${this.props.name}`} />
                <DressOverlay />
                <DressInfo>
                    <h2 className="script">{this.props.name}</h2>
                    <SaleInfo className="subtext">
                        {/* onSale */}
                        <span className={(this.props.onSale ? 'is-showing' : '')}>{this.props.size}, {this.props.price} <strong>{`now £${this.props.salePrice}`}</strong> {this.props.note}</span>

                        {/* Non-Sale */}
                        <span className={(!this.props.onSale && this.props.price ?'is-showing' : '')}>{this.props.size}, {this.props.price} {this.props.note}</span>
                        
                    </SaleInfo>
                    {this.props.budget ?
                    <BudgetInfo
                        className={(`subtext ${this.props.budget ? 'is-showing' : ''}`)}>
                        {this.props.budgetDetails.map((item, i) => <span key={i}>{`${item.size}: ${item.price}`}</span>)}
                    </BudgetInfo> : false}
                </DressInfo>
             </DressCards>
        </DressContainer>
    );
  }
}

export default DressCard;
